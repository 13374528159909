import * as React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Mission from "../../components/mission";

const MissionMessage = () => (
  <Layout>
    <Seo title="Our Mission" />
    <Mission />
  </Layout>
);

export default MissionMessage;
